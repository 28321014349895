import axios from "axios";
import { environment } from '../../config.env'
import { v4 as uuidv4 } from 'uuid'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function getDepartment() {
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/department?HospitalCode=${localStorage.getItem('hospital_code')}`
        let header = {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findDepartment(department_code) {
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/department?HospitalCode=${localStorage.getItem('hospital_code')}&DepartmentCode=${department_code}`
        let header = {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`

        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function addDepartment(department_code,department_name,department_type,location,link_confernceUrl,dataDay,line_notify_token,clinic){  
   
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/department`
        let body = ''
        if(department_type === 'APPOINTMENT'){
             body = {
                HospitalCode: localStorage.getItem('hospital_code'),
                DepartmentCode: department_code,
                // ClinicCode: department_code,
                DepartmentName: department_name,
                DepartmentType: department_type,
                DepartmentDetail: {
                    Sunday: {
                        Open: dataDay[0]['status_open'],
                        TimeOpen: dataDay[0]['time_open'],
                        TimeClose: dataDay[0]['time_close']
                    },
                    Monday: {
                        Open: dataDay[1]['status_open'],
                        TimeOpen: dataDay[1]['time_open'],
                        TimeClose: dataDay[1]['time_close']
                    },
                    Tuesday: {
                        Open: dataDay[2]['status_open'],
                        TimeOpen: dataDay[2]['time_open'],
                        TimeClose: dataDay[2]['time_close']
                    },
                    Wednesday: {
                        Open: dataDay[3]['status_open'],
                        TimeOpen: dataDay[3]['time_open'],
                        TimeClose: dataDay[3]['time_close']
                    },
                    Thursday: {
                        Open: dataDay[4]['status_open'],
                        TimeOpen: dataDay[4]['time_open'],
                        TimeClose: dataDay[4]['time_close']
                    },
                    Friday: {
                        Open: dataDay[5]['status_open'],
                        TimeOpen: dataDay[5]['time_open'],
                        TimeClose: dataDay[5]['time_close']
                    },
                    Saturday: {
                        Open: dataDay[6]['status_open'],
                        TimeOpen: dataDay[6]['time_open'],
                        TimeClose: dataDay[6]['time_close']
                    }
                },
                Room: {
                    Location: location,
                    ConferenceURL: link_confernceUrl
                },
                LineNotifyToken: line_notify_token,
                // Clinic:clinic
    
            }
        
        }else{
             body = {
                HospitalCode: localStorage.getItem('hospital_code'),
                // DepartmentCode: department_code,
                ClinicCode: department_code,
                DepartmentName: department_name,
                DepartmentType: department_type,
                DepartmentDetail: {
                    Sunday: {
                        Open: dataDay[0]['status_open'],
                        TimeOpen: dataDay[0]['time_open'],
                        TimeClose: dataDay[0]['time_close']
                    },
                    Monday: {
                        Open: dataDay[1]['status_open'],
                        TimeOpen: dataDay[1]['time_open'],
                        TimeClose: dataDay[1]['time_close']
                    },
                    Tuesday: {
                        Open: dataDay[2]['status_open'],
                        TimeOpen: dataDay[2]['time_open'],
                        TimeClose: dataDay[2]['time_close']
                    },
                    Wednesday: {
                        Open: dataDay[3]['status_open'],
                        TimeOpen: dataDay[3]['time_open'],
                        TimeClose: dataDay[3]['time_close']
                    },
                    Thursday: {
                        Open: dataDay[4]['status_open'],
                        TimeOpen: dataDay[4]['time_open'],
                        TimeClose: dataDay[4]['time_close']
                    },
                    Friday: {
                        Open: dataDay[5]['status_open'],
                        TimeOpen: dataDay[5]['time_open'],
                        TimeClose: dataDay[5]['time_close']
                    },
                    Saturday: {
                        Open: dataDay[6]['status_open'],
                        TimeOpen: dataDay[6]['time_open'],
                        TimeClose: dataDay[6]['time_close']
                    }
                },
                Room: {
                    Location: location,
                    ConferenceURL: link_confernceUrl
                },
                LineNotifyToken: line_notify_token,
                // Clinic:clinic
    
            }
        }

        // console.log("body add ====> ", body)

        let header = {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.post(url, body,{ headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function editDepartment(department_code,department_name,department_type,location,link_confernceUrl,dataDay,line_notify_token,clinic){
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/department?HospitalCode=${localStorage.getItem('hospital_code')}&DepartmentCode=${department_code}`
        let body = {
            // HospitalCode: localStorage.getItem('hospital_code'),
            // DepartmentCode: department_code,
            DepartmentName: department_name,
            DepartmentType: department_type,
            DepartmentDetail: {
                Sunday: {
                    Open: dataDay[0]['status_open'],
                    TimeOpen: dataDay[0]['time_open'],
                    TimeClose: dataDay[0]['time_close']
                },
                Monday: {
                    Open: dataDay[1]['status_open'],
                    TimeOpen: dataDay[1]['time_open'],
                    TimeClose: dataDay[1]['time_close']
                },
                Tuesday: {
                    Open: dataDay[2]['status_open'],
                    TimeOpen: dataDay[2]['time_open'],
                    TimeClose: dataDay[2]['time_close']
                },
                Wednesday: {
                    Open: dataDay[3]['status_open'],
                    TimeOpen: dataDay[3]['time_open'],
                    TimeClose: dataDay[3]['time_close']
                },
                Thursday: {
                    Open: dataDay[4]['status_open'],
                    TimeOpen: dataDay[4]['time_open'],
                    TimeClose: dataDay[4]['time_close']
                },
                Friday: {
                    Open: dataDay[5]['status_open'],
                    TimeOpen: dataDay[5]['time_open'],
                    TimeClose: dataDay[5]['time_close']
                },
                Saturday: {
                    Open: dataDay[6]['status_open'],
                    TimeOpen: dataDay[6]['time_open'],
                    TimeClose: dataDay[6]['time_close']
                }
            },
            Room: {
                Location: location,
                ConferenceURL: 
                    link_confernceUrl
                
            },
            LineNotifyToken: line_notify_token,
            // Clinic:clinic
        }

        let header = {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.put(url, body,{ headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
} 

export function deleteClinicAllDepartment(department_code){
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/clinic?DepartmentCode=${department_code}`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function deleteDepartment(department_code){
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/department?HospitalCode=${localStorage.getItem('hospital_code')}&DepartmentCode=${department_code}`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function getClinic(department_code){
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/clinic?DepartmentCode=${department_code}`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function addClinic(department_code, hospital_code ,hospital_name){
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/clinic`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        let body = {
            DepartmentCode: department_code,
            // ClinicID: uuidv4(),
            ClinicCode: hospital_code,
            ClinicName: hospital_name
        }

        // console.log('body clinic ====> ', body)

        axios.post(url, body,{ headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}


export function deleteClinic(id){
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/clinic?ID=${id}`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })        
    })
}

