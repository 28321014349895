import React, { useEffect, useRef, useState } from 'react'

const formatTime = (time) => {
    let minutes = Math.floor(time / 60)
    let seconds = Math.floor(time - (minutes * 60))

    if(minutes <= 10){
        minutes = '0' + minutes
    } 
    if(seconds <= 10){
        seconds = '0' + seconds
    }

    return minutes + ':' + seconds
}

function CountDownTime({seconds}) {
    const [countDownTime,setCountDownTime] = useState(seconds)
    const timeId = useRef()

    useEffect(() => {
        timeId.current = setInterval(() => {
            setCountDownTime((prev) => prev - 1)
        },1000)
        return () => clearInterval(timeId.current)
    },[])

    useEffect(() => {
        if(countDownTime <= 0){
            clearInterval(timeId.current)
        }
    },[countDownTime])

  return (
    <>
        {formatTime(countDownTime)} นาที
    </>
  )
}

export default CountDownTime