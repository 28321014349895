import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Box, Button } from "@mui/material";
import PointOfCare from "./PointOfCare";
import { useLocation } from "react-router-dom";
import { SystemPatientInformationData } from "./SystemPatientInformationData";

export default function SystemPatientInformation() {

    const location = useLocation()
    const [value, setValue] = useState(0);
    const [pid, setPid] = useState(new URLSearchParams(location.search).get('PID'))

    return (
        <>
            {/* <PageHeader title_name={'กลับไปที่ผู้ป่วยนัด'} back_path={'/auth/consult_doctor'} hide_date /> */}
            
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 3,
                paddingTop: 5,
                '@media (max-width: 1023px)': {
                    margin: 2,
                    gap: 2
                },
                '@media (max-width: 800px)': {
                    margin: 1,
                    gap: 1
                }
            }}>
                <Button onClick={() => { setValue(0) }} variant={value === 0 ? "contained" : "outlined"} >
                    ประวัติการแพทย์
                </Button>
                <Button onClick={() => { setValue(1) }} variant={value === 1 ? "contained" : "outlined"} >
                    Point of Care
                </Button>
            </Box>
            {value === 0 ? 
                <>
                    <SystemPatientInformationData
                        pid={pid}
                    />
                </> : null
            }
            {value === 1 ?
                <>
                    <PointOfCare
                        pid={pid}
                    />
                </> : null
            }
        </>
    )
}