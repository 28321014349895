import React, { useEffect, useState } from "react";
import { backgroundModal, boxModalV4 } from './ModalAccessCss'
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import * as api from '../../services/ApiDrugDelivery'
import * as apiPublic from '../../services/public_api'
import LoaderAnimation from '../../components/LoaderAnimation'
import { notifyError, notifyInfo, notifySuccess } from "../NotifyToast";

export default function ModalCheckAddress({ onClose, pid, DocNumber }) {

    const [loading, setLoading] = useState(false)

    const [pname, setPname] = useState('')
    const [optionPname, setOptionPname] = useState([])
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')

    const [edit, setEdit] = useState(false)

    const [data, setData] = useState([])

    useEffect(() => {
        findTitleName()
    }, [])

    useEffect(() => {
        if (pid !== '') {
            findAddress()
        }
    }, [pid])

    const findTitleName = async () => {
        setLoading(true)
        let res = await apiPublic.findTitleName()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setOptionPname(res['data']['data'])
            } else {
                setOptionPname([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findAddress = async () => {
        setLoading(true)
        let res = await api.findAddress(pid)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setData(res['data']['data'])
                setAddress(res['data']['data'][0]['Address'])
                setPhone(res['data']['data'][0]['Phone'])
                
                const fullname = res['data']['data'][0]['Name'].split(" ");
                // console.log("fullname ====> ",fullname)
                setPname(fullname[0])
                setFname(fullname[1])
                setLname(fullname[2])
            } else {
                setData([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const confirmAddress = async (body) => {
        setLoading(true)
        let res = await api.confirmAddress(DocNumber, body)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess(res['data']['message'])
                onClose()
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const clickEdit = () => {
        setEdit(!edit)
    }
    const clickCancel = () => {
        setEdit(!edit)
    }

    const clickSubmit = () => {
        if (!pname && pname !== ' ') {
            notifyInfo('กรุณาเลือกคำนำหน้า')
            return
        }
        if (!fname) {
            notifyInfo('กรุณากรอกชื่อ')
            return
        }
        if (!lname) {
            notifyInfo('กรุณากรอกนามสกุล')
            return
        }
        if (!address) {
            notifyInfo('กรุณาที่อยู่')
            return
        }
        if (!phone) {
            notifyInfo('กรุณาเบอร์โทรศัพท์')
            return
        }

        let body = {
            "PID": pid,
            "Name": pname + ' ' + fname + ' ' + lname,
            "Address": address,
            "Phone": phone
        }
        confirmAddress(body)
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={backgroundModal}>
                <Box sx={boxModalV4}>
                    <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }} onClick={() => { onClose() }}><ClearIcon />
                    </Box>
                    <Box sx={{
                        fontSize: 18,
                        display: 'grid',
                        gridGap: 20,
                        '@media (max-width: 1023px)': {

                        },
                        '@media (max-width: 800px)': {
                            fontSize: 14,
                        }
                    }}>
                        {edit ?
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                                    <div>ตรวจสอบที่อยู่</div>
                                </Box>

                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr 1fr',
                                    gridGap: 20,
                                    '@media (max-width: 1023px)': {

                                    },
                                    '@media (max-width: 800px)': {
                                        gridTemplateColumns: '1fr',
                                        gridGap: 10,
                                    }
                                }}>
                                    <FormControl >
                                        <div>คำนำหน้า</div>
                                        <Select
                                            size="small"
                                            value={pname || ' '}
                                            onChange={(e) => { setPname(e.target.value) }}
                                        >
                                            <MenuItem value=" ">เลือกคำนำหน้า</MenuItem>
                                            {optionPname.map((i, idx) => (
                                                <MenuItem
                                                    key={idx}
                                                    value={i['TitleName']}
                                                >{i['TitleName']}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <div>ชื่อ</div>
                                        <TextField
                                            placeholder={'ชื่อ'}
                                            size="small"
                                            value={fname}
                                            onChange={(e) => { setFname(e.target.value) }}
                                        ></TextField>
                                    </FormControl>
                                    <FormControl>
                                        <div>นามสกุล</div>
                                        <TextField
                                            placeholder={'นามสกุล'}
                                            size="small"
                                            value={lname}
                                            onChange={(e) => { setLname(e.target.value) }}
                                        ></TextField>
                                    </FormControl>
                                </Box>

                                <Box>
                                    <div>ที่อยู่</div>
                                    <TextField
                                        placeholder={'ที่อยู่'}
                                        multiline
                                        fullWidth
                                        rows={2}
                                        value={address}
                                        onChange={(e) => { setAddress(e.target.value) }}
                                    >
                                    </TextField>
                                </Box>

                                <Box>
                                    <div>เบอร์โทรศัพท์</div>
                                    <TextField
                                        placeholder={'เบอร์โทรศัพท์'}
                                        size="small"
                                        value={phone}
                                        onChange={(e) => { setPhone(e.target.value) }}
                                    >
                                    </TextField>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gridGap: 10
                                }}>
                                    <Button
                                        variant="outlined"
                                        style={{ width: 100 }}
                                        onClick={() => { clickCancel() }}
                                    >ยกเลิก</Button>
                                    <Button
                                        variant="contained"
                                        style={{ width: 100 }}
                                        onClick={() => { clickSubmit() }}
                                    >บันทึก</Button>
                                </Box>
                            </>
                            :
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                                    <div>ตรวจสอบที่อยู่</div>
                                    <Button
                                        variant="contained"
                                        style={{ width: 100 }}
                                        onClick={() => { clickEdit() }}
                                    >แก้ไข</Button>
                                </Box>
                                {data.length === 0 ?
                                    <div style={{ textAlign: 'center', fontSize: 20, margin: '40px' }}>ไม่พบข้อมูล</div>
                                    :
                                    <>
                                        {data.map((i, idx) => (
                                            <Box key={idx} sx={{
                                                display: 'grid',
                                                gridGap: 20
                                            }}>
                                                <Box>
                                                    <div>ชื่อ-นามสกุล</div>
                                                    <Typography sx={{
                                                        color: '#747676',
                                                        border: '1px solid #E1E3E9',
                                                        borderRadius: 3,
                                                        padding: 1
                                                    }}>{i['Name']}</Typography>
                                                </Box>

                                                <Box >
                                                    <div>ที่อยู่</div>
                                                    <Typography sx={{
                                                        color: '#747676',
                                                        border: '1px solid #E1E3E9',
                                                        borderRadius: 3,
                                                        padding: 1
                                                    }}>{i['Address']}</Typography>
                                                </Box>

                                                <Box>
                                                    <div>เบอร์โทรศัพท์</div>
                                                    <Typography sx={{
                                                        color: '#747676',
                                                        border: '1px solid #E1E3E9',
                                                        borderRadius: 3,
                                                        padding: 1
                                                    }}>{i['Phone']}</Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                    </>
                                }

                            </>
                        }
                    </Box>
                </Box>
            </Box>
        </>

    )
}