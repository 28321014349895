import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TextField,InputAdornment } from '@mui/material';


export function InputTextNormal({
    name,
    label_input,
    value,
    onChange,
    disabled,
    validate,
    maxLength,
    placeholder,
    size,
    readOnly
}) {
    return (
        <TextField
            fullWidth
            label={label_input}
            type={'text'}
            error={validate ? validate : false}
            value={value}
            name={name}
            size={size ? size : 'medium'}
            disabled={disabled}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{
                inputProps: {
                    maxLength: maxLength,
                    readOnly: readOnly? readOnly : false,
                },
            }}
            onChange={(e) => { onChange(e) }}
        />
    )
}


export function InputTextPassword({
    label_input,
    onChange,
    value,
    name,
    validate,
    variant
}) {

    const [onTypePassword, setOnTypePassword] = useState(false)

    return (
        <TextField
            fullWidth
            label={label_input}
            type={onTypePassword ? 'text' : 'password'}
            value={value}
            error={validate ? validate : false}
            name={name}
            size="small"
            variant={variant ? variant : 'outlined'}
            sx={{
                input: { color: '#673ab7' }, width: '400px',
                '@media (max-width: 600px)': {
                    width: '100%'
                }
            }}
            onChange={(e) => { onChange(e) }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => { setOnTypePassword(!onTypePassword) }}>
                            {onTypePassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}

export function InputTextSearch({
    label_input,
    onChange,
    value,
    name,
    validate,
    variant,
    maxLength,
    placeholder,
    submitForm,
    disabled
}) {
    return (
        <TextField
            label={label_input}
            placeholder={placeholder}
            disabled={disabled || false}
            variant={variant ? variant : "standard"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => submitForm()}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
                inputProps: {
                    maxLength: maxLength,
                }
            }}

            value={value}
            name={name}
            onChange={(e) => {
                onChange(e)
            }}
            // error={validate}
            sx={{
                mt: '25px',
                mb: '25px',
                width: '100%',
                '@media (max-width: 600px)': {
                    width: '100%'
                }
            }}
        />
    )
}