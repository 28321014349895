import React,{useState,useEffect} from 'react'
import { Box, Grid, Pagination, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography,Button } from '@mui/material'
import { buttonPrimary,contentBox,boxContent,boxAddRoom,contentAddRoom,boxService, contentService, buttonSecondary, boxSearch } from './mainCss'
import DeleteIcon from '@mui/icons-material/Delete';
import ModalAddHospital from '../../components/ModalController/ModalAddHospital';
import LoaderAnimation from '../../components/LoaderAnimation';
import { notifyError , notifyInfo , notifySuccess , notifyWarning } from '../../components/NotifyToast';
import * as apiManage from '../../services/Manage/ApiManageDepartment'
import ModalSuccess from '../../components/ModalController/ModalSuccess';
import { useLocation, useNavigate } from 'react-router-dom';

function AddClinic() {
    const routeState = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [department,setDepartment] = useState({
        department_code: '',
        department_name: '',
        department_type: '',
        location:'',
        room_conference: '1',
        line_notify_token: ''
        // link_conference:''
    })
    const [addHospital,setAddHospital] = useState(false)
    const [formAdd,setFormAdd] = useState({
        hospital_code : '',
        hospital_name: ''
    })
    const [divHospital,setDivHospital] = useState([])
    const [modalSuccessAddHospital,setModalAddHospital] = useState(false)

    // if(!routeState.state.department_code){
    //     navigate('/auth/manage_user',{state : {typeManage: 'hospital', Tap: 'department'}})
    // }

    useEffect(() => {
        setDepartment({...department,['department_code']: routeState.state.department_code})
        getClinic()
    },[])

    const getClinic = async() => {
        setLoading(true)
        let res = await apiManage.getClinic(routeState.state.department_code)
        setLoading(false)
        if(res){
            // console.log("res getClinic ====> ",res)
            if(res['data']['code'] === 200){
                setDivHospital(res['data']['data'])
            }else{
                setDivHospital([])
            }
        }else{
            return notifyError('ระบบไม่ตอบสนอง')
        }
      }

        useEffect(() => {
            setFormAdd({
                hospital_name:''
            })
        },[addHospital])

      const addMoreHospital = async() => {
        if(!formAdd['hospital_name'] && !formAdd['hospital_code']){
            return notifyWarning('กรุณาเลือก สถานพยาบาล')
        }
        if(!department['department_code']){
            return notifyWarning('กรุณากรอก รหัสแผนก')
        }

        setLoading(true)
        let res = await apiManage.addClinic(department['department_code'],formAdd['hospital_code'],formAdd['hospital_name'])
        setLoading(false)
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                await getClinic()
                setAddHospital(false)
            }else{
                return notifyInfo(res['data']['message'])
            }
        }else{
            return notifyError('ระบบไม่ตอบสนอง')
        }
      }

      const deleteHospital = async(id_delete) => {
        // console.log("id_delete",id_delete)
        setLoading(true)
        let res = await apiManage.deleteClinic(id_delete)
        setLoading(false)
        if(res){
            if(res['data']['code'] === 200){
                await getClinic()
                setModalAddHospital(true)
            }
        }else{
            return notifyError('ระบบไม่ตอบสนอง')
        }
      }

      const handleAddHospital = (e) => {
        const {name,value} = e.target
        setFormAdd({...formAdd,[name]:value})
      }
  return (
    <div>
        {
            addHospital? (
                <ModalAddHospital
                    closeModal={() => setAddHospital(false)}
                    selectedValue={setFormAdd}
                    submitForm={() => addMoreHospital()}
                />
            ) : ''
        }
        {
            modalSuccessAddHospital ? (
                <ModalSuccess
                    title={'ลบสถานพยาบาลที่ต้องการ ขอคำปรึกษา'}
                    detail={'ท่านได้ลบสถานพยาบาลที่ต้องการ ขอคำปรึกษาเรียบร้อยแล้ว'}
                    closeModal={() => setModalAddHospital(false)}
                    goPage={'closeModal'}
                />
            ) : ''
        }
        <Box sx={contentBox}>
             <Box sx={boxContent}>
                <Typography component="p" variant="p" sx={{fontWeight:'bold',marginBottom:'2rem'}}>
                    เพิ่มสถานพยาบาลที่ต้องการ ขอคำปรึกษา
                </Typography>
                <Grid container spacing={3}>
                    {
                        divHospital.map((item,idex) => (
                            <Grid key={idex} item xs={12} sm={6} md={6} lg={4} xl={4}>
                                <Box sx={{border:'1px solid #E1E3E9',padding:'0.5rem',borderRadius:'0.5rem',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                    <Typography component="p" variant="p" 
                                        sx={{fontWeight:'bold'}}>
                                        {item['ClinicName']}
                                    </Typography>
                                    <Button
                                        onClick={() => deleteHospital(item['ID'])}
                                    >
                                        <DeleteIcon/>
                                        <Typography component="span" variant="span" 
                                            sx={{fontWeight:'bold',marginLeft:'0.25rem'}}>
                                            ลบ
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
                <Box 
                    sx={{display:'flex',justifyContent:'center',marginTop:'2rem'}}
                >
                    <Button
                        sx={buttonPrimary}
                        onClick={() => setAddHospital(true)}
                    >
                        เพิ่มสถานพยาบาล
                    </Button>
                </Box>
            </Box>
        </Box>
    </div>
  )
}

export default AddClinic