import React from "react";
import { Box, Button } from "@mui/material";
import { backgroundModal2 , boxModalSOS } from './ModalAccessCss'
import ClearIcon from '@mui/icons-material/Clear';
import warning from '../../assets/icons/warning.svg'
import styles from '../../pages/sos/StylesSos.module.css'
import dayjs from 'dayjs';
import buddhistEra from "dayjs/plugin/buddhistEra";
import 'dayjs/locale/th';
import { notifyError } from "../NotifyToast";
dayjs.extend(buddhistEra);
dayjs.locale('th');

export default function ModalSOS({ onClose, payload }) {

    const data = {
        name: 'นาย ทดลอง ระบบ',
        pid: '1234567890123',
        phone: '0912345678',
        time: '12.00 น.',
        latitude: '16.7517589',
        longitude: '100.1910909'
    }

    const {
        broadcastDate,
        uid,
        phone,
        email,
        name,
        gps,
        pid
    } = payload

    // console.log("====> ",payload)

    let parse_lat_lng = gps.split(',') //output array of latitude and longitude

    let latitude = parse_lat_lng[0]
    let longitude = parse_lat_lng[1]



    const clickOpenMap = (latitude, longitude) => {
        onClose()
        window.open(`https://maps.google.com/?q=${latitude},${longitude}`, '_system')
    }

    const clickMedicalHistory = (uid) => {
        window.open(`/systemPatientInformationData?PID=${uid}`)
    }

    return (
        <>
            <Box sx={backgroundModal2}>
                <Box sx={{
                    color: '#FFFFFF',
                    fontSize: 20,
                    backgroundColor: '#DE0000',
                    width: 600,
                    height: 'auto',
                    padding: 3,
                    borderRadius: '10px',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                    '@media (max-width: 1030px)': {
                        width: 450,
                        padding: 2,
                        fontSize: 16,
                    },
                    '@media (max-width: 600px)': {
                        width: 350,
                        padding: 1,
                        fontSize: 14,
                    },
                }}>
                    <Box
                        sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }}
                        onClick={() => { onClose() }}>
                        <ClearIcon sx={{ color: '#FFFFFF' }} />
                    </Box>
                    <Box sx={{
                        display: 'grid',
                        gridGap: 20,
                        '@media (max-width: 800px)': {
                            gridGap: 10
                        },
                    }}>
                        <div>ขอความช่วยเหลือ</div>
                        <div style={{ borderBottom: '1px solid #FFFFFF' }}></div>
                        <Box sx={{
                            display: 'flex',
                            gridGap: 5,
                            fontSize: 20,
                            '@media (max-width: 1100px)': {
                                fontSize: 16,
                            },
                            '@media (max-width: 600px)': {
                                fontSize: 12,
                            },
                        }}>
                            <img className={styles.Img} src={warning} />
                            <Box sx={{
                                display: 'grid',
                                gridGap: 15,
                                '@media (max-width: 800px)': {
                                    gridGap: 5
                                },
                            }}>
                                <div>ชื่อ-นามสกุล : {name}</div>
                                <div>เลขบัตรประจำตัวประชาชน : {uid ? uid : '-'}</div>
                                <div>เบอร์โทรศัพท์ : {phone}</div>
                                <div>อีเมล : {email ? email : '-'}</div>
                                <div>เวลา : {dayjs(broadcastDate).format("HH:mm")} น.</div>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gridGap: 20,
                            justifyContent: 'center',
                        }}>
                            <Button
                                sx={{
                                    background: '#FFFFFF',
                                    color: '#DE0000',
                                    width: '30%',
                                    ":hover": {
                                        background: '#FFFFFF'
                                    },
                                    '@media (max-width: 1100px)': {
                                        width: '50%',
                                    },
                                }}
                                onClick={() => { clickOpenMap(latitude, longitude) }}
                            >นำทาง</Button>
                            <Button
                                sx={{
                                    background: '#FFFFFF',
                                    color: '#DE0000',
                                    width: '30%',
                                    ":hover": {
                                        background: '#FFFFFF'
                                    },
                                    '@media (max-width: 1100px)': {
                                        width: '50%',
                                    },
                                }}
                                onClick={() => {
                                    if (!uid) {
                                        notifyError('ไม่พบข้อมูลรหัสบัตรประชาชน')
                                    } else {
                                        clickMedicalHistory(uid)
                                    }

                                }}
                            >ประวัติทางการแพทย์</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}