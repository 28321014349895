import axios from "axios";
import { environment } from '../config.env'
import { register } from "./public_api";

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function auth(ones_did){
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/authen/onesDiDs/auth`
        let body = {
            "ones_did": ones_did 
        }
        let header = {
            'Content-Type': 'application/json',
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function checkAuth(auth_req_id){
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/authen/onesDiDs/checkAuth`
        let body = {
            "auth_req_id": auth_req_id 
        }
        let header = {
            'Content-Type': 'application/json',
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function loginOnesId(ones_did){
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/authen/login/onesdid`
        let body = {
            "ones_did": ones_did 
        }
        let header = {
            'Content-Type': 'application/json',
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function verifyOnesId(ones_did,auth_req_id){
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/authen/verifyOnesDiD`
        let body = {
            "ones_did": ones_did,
        }
        let header = {
            'Content-Type': 'application/json',
            "Authorization" : `Bearer ${auth_req_id}`
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })   
}

export function getUserData(access_token){
    return new Promise(resolve => {
        let url = `${domain}/dashboard-api/api/v1/authen/onesDiDs/getUserData`
        let body = {
            "access_token": access_token
        }
        let header = {
            'Content-Type': 'application/json',
        }
        axios.post(url, body, { headers: header }).then(res => {
            resolve(res)
        }).catch(() => {
            resolve(false)
        })
    })
}

export function registerOnesId(
        email,
        password,
        cid,
        title_name,
        fname,
        lname,
        confirm_register,
        group_role,
        hospital_code,
        phone,
        department_code,
        position
    ){
    return new Promise((resolve) => {
        let url =  `${domain}/dashboard-api/api/v1/authen/register/onesdid`
        let body = {
            ones_did: email,
            Password: password,
            CID: cid,
            TitleName: title_name,
            FirstName: fname,
            LastName: lname,
            Position: position,
            PhoneNumber: phone,
            Confirm: confirm_register,
            Permission:{
                GroupRole: group_role,
                HospitalCode: hospital_code,
                DepartmentCode: department_code
            }
        }
        let header = {
            'Content-Type': 'application/json'
        }

        axios.post(url,body,{headers: header}).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}