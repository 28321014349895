import React, { useEffect, useState } from "react";
import Maps from "./Maps";
import * as ApiSoS from '../../services/ApiSOS'
import LoaderAnimation from "../../components/LoaderAnimation";
import { notifyError, notifyInfo } from "../../components/NotifyToast";

export default function HelpRequesterMap({

}) {

    const [loading, setLoading] = useState(false)

    const [dataSOSList, setDataSOSList] = useState([])
    const [longitude, setLongitude] = useState(100.1910909)
    const [latitude, setLatitude] = useState(16.7517589)

    useEffect(() => {
        findSOS()
    }, [])


    const findSOS = async () => {

        setLoading(true)
        let res = await ApiSoS.findSOS([`State=1`, `order=${'CreatedDate'}`, `sort=${'desc'}`])
        setLoading(false)
        if (res) {
            if (res['data']['status']) {
                setDataSOSList(res['data']['data'])
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            return notifyError('ไม่ตอบสนอง')
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5%', position: 'relative', zIndex: 10 }}>
            <LoaderAnimation loading={loading} />
            <div style={{ width: 1000, height: 500 }}>
                <Maps
                    disabled={true}
                    latitude={latitude}
                    longitude={longitude}
                    arrayList={dataSOSList}
                />
            </div>
        </div>
    )
}