import React from "react";
import { backgroundModal, boxModal } from './ModalAccessCss'
import ContactConference from "../../pages/record_patient/ContactConference";
import { Box } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

export default function ModalContactConference({ onClose, pid }) {
    return (
        <>
            <Box sx={backgroundModal}>
                <Box sx={boxModal}>
                    <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' }, margin:'0.5rem 0' }} onClick={() => { onClose() }}><ClearIcon /></Box>
                    <Box>
                        <ContactConference
                            pid={pid}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
}