import React, { useState } from 'react'
import PageHeader from '../../components/PageHeader'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import AddAppointment from './AddAppointment';
import FilterPoint from './FilterPoint';
import MedicalRecord from './MedicalRecord';
import TakeHistory from './TakeHistory';
import SeeDoctor from './SeeDoctor';
import Appointment from './Appointment';
import DispenseMedication from './DispenseMedication';
import { useLocation, useNavigate } from 'react-router'
import { useDispatch } from 'react-redux';
import { menuActive  } from '../../store/menuSlice';
import { useEffect } from 'react';

function OutPatient() {
  const navigate = useNavigate()
  const routeState = useLocation()
  const dispatch = useDispatch()

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  if(!routeState.state){
    routeState.state = { appointment_state: 'NEW_APPOINT' }
  }

  let init_button_feature = [
    {
      name_btn: 'เพิ่มการนัดหมาย',
      state: 'NEW_APPOINT',
      status: false
    },
    {
      name_btn: 'จุดคัดกรอง',
      state: 'SCREEN_APPOINT',
      status: false
    },
    {
      name_btn: 'เวชระเบียน',
      state: 'MEDICAL_RECORD',
      status: false
    },
    {
      name_btn: 'ซักประวัติ',
      state: 'TAKE_HISTORY',
      status: false
    },
    {
      name_btn: 'พบแพทย์',
      state: 'DOCTOR',
      status: false
    },
    {
      name_btn: 'จุดออกใบนัด',
      state: 'NEXT_APPOINT',
      status: false
    },
    {
      name_btn: 'จ่ายยา',
      state: 'DRUG_ORDER',
      status: false
    },
  ]

  useEffect(() => {
    dispatch(menuActive('ผู้ป่วยนอก (ทางไกล)'))
  },[])

  const handleManage = (state) => {
    navigate("/auth/out_patient", { state: { appointment_state: state }})
  }
  //  console.log("routeState ====> ",routeState.state['appointment_state'])

  return (
    <>
      {/* <PageHeader 
        title_name={
          value === 0 ? 'รายชื่อผู้ป่วย' :  value === 1 ? 'นัดหมาย' : ''
        } 
        hide_date={
          value === 1 ? true : ''
        }
        back_path={
          '/auth/out_patient'
        } 
      /> */}
      {/* <Box sx={{ width: '100%'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab label="เพิ่มการนัดหมาย" />
          <Tab label="จุดคัดกรอง" />
          <Tab label="เวชระเบียน" />
          <Tab label="ซักประวัติ" />
          <Tab label="พบแพทย์" />
          <Tab label="จุดออกใบนัด" />
          <Tab label="จ่ายยา" />
        </Tabs>
      </Box> */}

      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: {xl:'row',lg:'row',md:'row',sm:'row',xs:'column'},
          justifyContent: 'center',
          marginTop: '20px', 
          gap: '15px' }}
      >
        {
          init_button_feature.map((item, index) => (
            <Button sx={{
              fontWeight: 'semibold'
            }} 
            disabled={item['status']} 
            key={index} 
            onClick={() => handleManage(item['state'],index)} 
            variant={item['state'] === routeState.state['appointment_state'] ? "contained" : "outlined"} 
            >
              {item['name_btn']}
            </Button>
          ))

        }
      </Box>


      {
        routeState.state['appointment_state'] === 'NEW_APPOINT' ? <AddAppointment /> : null
      }

      {
        routeState.state['appointment_state'] === 'SCREEN_APPOINT' ? <FilterPoint /> : null
      }

      {
        routeState.state['appointment_state'] === 'MEDICAL_RECORD' ? <MedicalRecord /> : null
      }

      {
        routeState.state['appointment_state'] === 'TAKE_HISTORY' ? <TakeHistory /> : null
      }

      {
        routeState.state['appointment_state'] === 'DOCTOR' ? <SeeDoctor /> : null
      }

      {
        routeState.state['appointment_state'] === 'NEXT_APPOINT' ? <Appointment /> : null
      }

      {
        routeState.state['appointment_state'] === 'DRUG_ORDER' ? <DispenseMedication /> : null
      }
    </>
  )
}

export default OutPatient