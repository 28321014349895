import bgImg from '../../assets/img/bg_login$register.png'


export const contentBox = {
    width:'100%',
    height:'100vh',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
    }

export const boxPassword = {
    position:'relative'
}
export const boxIcon = {
    position:'absolute',
    top:'1rem',
    right:'1rem',
    color:'#747676'
}