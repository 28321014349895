
import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import authReducer from './authSlice';
import audioReducer from './audioSlice';
import menuReducer from './menuSlice';

const appReducer = combineReducers({
    auth: authReducer,
    sound_audio: audioReducer,
    menu_active: menuReducer
    //  if we need to use more reducers
});

const rootReducer = (state, action) => {
    if (action.type === 'auth/logout') {
        storage.removeItem('persist:root')
        state = {};
    }
    return appReducer(state, action);
};

export default rootReducer;