import { Outlet } from "react-router-dom"
import { Box } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import AppHeader from "../components/AppHeader"
import SideNav from "../components/SideNav"

const MenuLayout = () => {
  return (
    <>
      <CssBaseline />
      <AppHeader />
      <Box sx={styles.container}>
        <SideNav />
        <Box component={"main"} sx={styles.mainSection}>
          <Outlet />
        </Box>
      </Box>
    </>
  )
}

const styles = {
  container: {
    display: "flex",
    bgcolor: "neutral.light"
  },
  mainSection: {
    px: {xl:4,lg:4,md:4,sm:2,xs:2},
    py : {xl:4,lg:4,md:4,sm:2,xs:2},
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
}

export default MenuLayout