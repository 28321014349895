import React, { useEffect, useState } from 'react'
import styles from './ModalSearchHistoryPatient.module.css'
import icon_modal_close from '../../assets/icons/modal-close.svg'
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import * as api from '../../services/ApiConsultDoctor'
import LoaderAnimation from '../LoaderAnimation'
import { notifyError, notifyInfo, notifySuccess, notifyWarning } from '../../components/NotifyToast';

function ModalController({ onClose, success, pid }) {

  const [loading, setLoading] = useState(false)

  const [search, setSearch] = useState('')

  const [findData, setFindData] = useState([])
  const [nofindData, setNoFindData] = useState('')


  const findInformationSearch = async () => {
    setLoading(true)
    let res = await api.findInformationSearch(search)
    setLoading(false)
    if (res) {
      if (res['data']['code'] === 200) {
        const unique = [...new Map(res['data']['data'].map((m) => [m.pid, m])).values()] //กรองชื่อซ้ำ
        setFindData(unique)
      } else {
        setNoFindData(res['data']['message'])
      }
    } else {
      notifyError('ไม่ตอบสนอง')
    }
  }

  const handleChange = (e) => {
    const numericRegex = /^[0-9]*$/;
    if(numericRegex.test(e.target.value)){
      setSearch(e.target.value)
    }
  }

  const clickSearch = () => {
    // if(!search){
    //   return notifyWarning('กรุณากรอก เลขบัตรประจำตัวประชาชน')
    // }
    setFindData([])
    setNoFindData('')

    findInformationSearch()
  }

  const clickOpen = (PID) => {
    onClose()
    success(true)
    pid(PID)
  }


  return (
    <>
      <LoaderAnimation loading={loading} />
      <div className={styles.overlay}>
        <div className={styles.modalController}>
          <div className={styles.closeButton}>
            <img src={icon_modal_close} alt='' onClick={() => {
              onClose(false)
            }} />
          </div>
          <div className={styles.contentModal}>
            <div className={styles.label__title__modal}>ค้นหาประวัติผู้ป่วย</div>

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 2,
              marginBottom: 4,
              width: '100%'
            }}>
              <TextField
                sx={{ width: '100%', fontSize: 18 }}
                variant="standard"
                placeholder={'ระบุเลขบัตรประจำตัวประชาชน'}
                type="text"
                value={search}
                onChange={(e) => { handleChange(e) }}
                InputProps={{
                  inputProps: {
                      maxLength: 13,
                  },
              }}
              >
              </TextField>
              <SearchTwoToneIcon style={{ cursor: 'pointer' }} onClick={() => { clickSearch() }} />
            </Box>

            {findData ?
              <>
                {findData.map((i, idx) => (
                  <Box sx={{
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E1E3E9',
                    borderRadius: 3,
                    display: 'grid',
                    gridTemplateColumns: '1fr 4fr',
                    alignItems: 'center',
                    padding: 2,
                    fontSize: 18,
                    cursor: 'pointer',
                    '@media (max-width: 500px)': {
                      padding: 1,
                      fontSize: 10
                    }
                  }}
                    key={idx}
                    onClick={() => { clickOpen(i['pid']) }}
                  >
                    <Box>
                      <img className={styles.imgProfile} src={i['image_url']} />
                    </Box>
                    <Box sx={{ display: 'grid', gridGap: 5 }}>
                      <Box sx={{ display: 'flex', gridGap: 10 }}>
                        <div>ชื่อ-นามสกุล : </div>
                        <div>{i['pname'] + i['fname'] + ' ' + i['lname']}</div>
                      </Box>
                      <Box sx={{ display: 'flex', gridGap: 10 }}>
                        <div>เลขประจำตัวประชาชน : </div>
                        <div>{i['pid']}</div>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </>
              : null
            }

            {nofindData ?
              <Box sx={{
                width: '100%',
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 3,
                display: 'grid',
                justifyItems: 'center',
                alignItems: 'center',
                padding: '1.5rem',
                fontSize: 18,
                '@media (max-width: 500px)': {
                  padding: '1rem',
                  fontSize: 15
                }
              }}
              >
                <div>ไม่พบข้อมูล</div>
              </Box>
              : null
            }

          </div>
        </div>
      </div>
    </>

  )
}

export default ModalController