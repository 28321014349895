import axios from "axios";
import { environment } from '../../config.env'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function getDrug() {
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/drug?DocStatus=W`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findDrugIdDrugGroup(id_drugGroup){
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/drug?DocStatus=W&DrugGroupCode=${id_drugGroup}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findDrug(id_drugGroup,id_drug){
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/drug?DocStatus=W&ID=${id_drug}&DrugGroupCode=${id_drugGroup}`
        let header = {
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}



export function addDrug(id_drugGroup,drug_name,drug_detail){
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/drug`
        let body = {
            DrugGroupCode: id_drugGroup,
            DrugName: drug_name,
            DrugDescription: drug_detail
        }

        let header = {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.post(url, body,{ headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function editDrug(id_drug,drug_name,drug_detail) {
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/drug?ID=${id_drug}`
        let body = {
            DrugName: drug_name,
            DrugDescription: drug_detail
        }

        let header = {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.put(url, body,{ headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function deleteDrug(id_drug) {
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/manage/drug?ID=${id_drug}`
        let header = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

