import React, { useEffect, useState } from "react";
import ModalSOS from '../../components/ModalController/ModalSOS'
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "leaflet/dist/leaflet.css"
import "react-leaflet-fullscreen/styles.css";

import L from 'leaflet'

delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    className: 'blinking'
});

export default function Maps({
    disabled,
    width,
    height,
    latitude,
    longitude,
    arrayList
}) {

    const [openModal, setOpenModal] = useState(false)
    const [payload, setPayload] = useState('')

    const clickMarker = (item) => {
        // console.log("====> ",item)
        // const latitude = e.latlng.lat
        // const longitude = e.latlng.lng
        setPayload((prevState) => ({
            ...prevState,
            broadcastDate: item['CreatedDate'],
            uid: item['CID'],
            phone: item['PhoneNumber'],
            email: item['-'],
            name: item['Name'],
            gps: item['Coordinates'],
            // pid: item['CID']
        }))
        setOpenModal(true)
    }

    return (
        <>
            {openModal ?
                <ModalSOS
                    payload={payload}
                    onClose={() => { setOpenModal(false) }}
                />
                :
                null
            }
            <MapContainer
                style={{ width: width ? width : '100%', height: height ? height : '100%', borderWidth: 1, borderColor: '#A2B5BC', borderRadius: 30 }}
                center={[latitude, longitude]}
                zoom={13}
                scrollWheelZoom={true}
            >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {arrayList && arrayList.map((item,index) => {
                    let parse_lat_lng = item.Coordinates.split(',') //output array of latitude and longitude
                    console.log(parse_lat_lng)
                    return (
                        <Marker
                            key={index}
                            position={[parse_lat_lng[0], parse_lat_lng[1]]}
                            eventHandlers={{
                                click: (e) => {
                                    clickMarker(item)
                                }
                            }}
                        >
                        </Marker>
                    )
                })}

                <FullscreenControl position="topright" />
            </MapContainer >
        </>
    )
}

